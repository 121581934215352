import React, { useContext, useEffect, useState } from "react";
import CustomTextInput from "../../components/CustomTextInput/CustomTextInput";
import { MyContext } from "../../components/contextProvider/MyContextProvider";
import { simplePostRequest } from "../../utils/Api";

function ProfileForm() {
  const { user, setUser } = useContext(MyContext);
  const [updateProfile, setUpdateProfile] = useState(false);
  const [pointOfContact, setPointOfContact] = useState(user?.pointOfContact);
  const [fullName, setFullName] = useState(user?.name);
  const [email, setEmail] = useState(user?.email);
  const [phone, setPhone] = useState(user?.phone);

  const [zipCode, setZipCode] = useState(user?.zip_code);
  const [address, setAddress] = useState(user?.address);
  const [service, setService] = useState(user?.service);
  const [subService, setSubService] = useState(user?.subService);
  const [socialSlug, setSocialSlug] = useState("");
  const [website, setWebsite] = useState(user?.web_address);
  const [loading, setLoading] = useState(false);
  const [profileImage, setProfileImage] = useState(user?.profileImage);
  const [errors, setErrors] = useState({
    pointOfContact: false,
    fullName: false,
    email: false,
    phone: false,
    password: false,
    confirmPassword: false,
    zipCode: false,
    address: false,
    service: false,
    subService: false,
    website: false,
    policyAccepted: false,
  });
  const [galleryImages, setGalleryImages] = useState([]);
  async function getImageUri(img) {
    try {
      const formdata = new FormData();
      formdata.append("file", img);
      const image = await simplePostRequest(
        "business-profile/image-uri",
        formdata,
        user?.jwtoken,
        "POST",
        true
      );
      console.log(image);
      if (image?.data?.uri) {
        return image?.data?.uri;
      }
      return;
    } catch (error) {
      console.log("getImageUri error: ");
    }
  }
  return (
    <div className="pf-pf-container">
      <div className="pf-pf-top-container">
        <h2 className="pf-pf-heading">User Profile</h2>
        <div>
          {updateProfile ? (
            <div>
              <button
                className="Button-Reg"
                style={{
                  marginRight: 10,
                }}
              >
                Save
              </button>
              <button
                onClick={() => setUpdateProfile(false)}
                className="Button-Reg"
              >
                Cancel
              </button>
            </div>
          ) : (
            <button
              onClick={() => setUpdateProfile(true)}
              className="Button-Reg"
            >
              Update Profile
            </button>
          )}
        </div>
      </div>
      <div
        onClick={() => {
          document.getElementById("profileInput").click();
        }}
        style={{
          border: "solid",
          borderWidth: 1,
          borderColor: "rgba(0,0,0,0.1)",
          width: "fit-content",
          padding: profileImage ? 0 : 20,
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: 10,
          borderRadius: 10,
          cursor: "pointer",
        }}
      >
        <input
          type="file"
          id="profileInput"
          style={{
            display: "none",
          }}
          multiple={false}
          onChange={(e) => {
            const value = URL.createObjectURL(e.target.files[0]);
            setProfileImage(value);
          }}
        />
        {profileImage ? (
          <img
            src={profileImage}
            style={{
              height: 130,
              width: 100,
              objectFit: "cover",
            }}
          />
        ) : (
          <>
            <div className="pf-pf-user-logo">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                fill="#fff"
                version="1.1"
                id="Capa_1"
                width="40px"
                height="40px"
                viewBox="0 0 45.402 45.402"
                xmlSpace="preserve"
              >
                <g>
                  <path d="M41.267,18.557H26.832V4.134C26.832,1.851,24.99,0,22.707,0c-2.283,0-4.124,1.851-4.124,4.135v14.432H4.141   c-2.283,0-4.139,1.851-4.138,4.135c-0.001,1.141,0.46,2.187,1.207,2.934c0.748,0.749,1.78,1.222,2.92,1.222h14.453V41.27   c0,1.142,0.453,2.176,1.201,2.922c0.748,0.748,1.777,1.211,2.919,1.211c2.282,0,4.129-1.851,4.129-4.133V26.857h14.435   c2.283,0,4.134-1.867,4.133-4.15C45.399,20.425,43.548,18.557,41.267,18.557z" />
                </g>
              </svg>
            </div>
            <p
              style={{
                fontWeight: "600",
              }}
            >
              Profile Image
            </p>
          </>
        )}
      </div>
      <div className="su-sub-left-row-container">
        <CustomTextInput
          heading={"Point of Contact"}
          type={"text"}
          value={pointOfContact}
          onChange={(text) => {
            setPointOfContact(text);
            setErrors({
              ...errors,
              pointOfContact: false,
            });
          }}
          hideBorder={!updateProfile}
          error={errors.pointOfContact}
        />
        <CustomTextInput
          heading={"Full Name"}
          type={"text"}
          value={fullName}
          hideBorder={!updateProfile}
          onChange={(text) => {
            setFullName(text);

            setErrors({
              ...errors,
              fullName: false,
            });
          }}
          error={errors.fullName}
        />
      </div>
      <div className="su-sub-left-row-container">
        <CustomTextInput
          heading={"Email"}
          type={"email"}
          disabled={socialSlug && email}
          value={email}
          hideBorder={!updateProfile}
          onChange={(text) => {
            setEmail(text);
            setErrors({
              ...errors,
              email: false,
            });
          }}
          error={errors.email}
        />
        <CustomTextInput
          heading={"Phone"}
          type={"tel"}
          value={phone}
          hideBorder={!updateProfile}
          onChange={(text) => {
            setPhone(text);
            setErrors({
              ...errors,
              phone: false,
            });
          }}
          error={errors.phone}
        />
      </div>

      <div className="su-sub-left-row-container">
        <CustomTextInput
          heading={"Zip Code"}
          type={"number"}
          style={{ width: "30%" }}
          value={zipCode}
          hideBorder={!updateProfile}
          onChange={(text) => {
            setZipCode(text);

            setErrors({
              ...errors,
              zipCode: false,
            });
          }}
          error={errors.zipCode}
        />
        <CustomTextInput
          heading={"Address"}
          type={"text"}
          value={address}
          hideBorder={!updateProfile}
          onChange={(text) => {
            setAddress(text);

            setErrors({
              ...errors,
              address: false,
            });
          }}
          error={errors.address}
        />
      </div>
      <div className="su-sub-left-row-container">
        <CustomTextInput
          heading={"Service"}
          type={"text"}
          value={service}
          hideBorder={!updateProfile}
          onChange={(text) => {
            setService(text);

            setErrors({
              ...errors,
              service: false,
            });
          }}
          error={errors.service}
        />
        <CustomTextInput
          heading={"Sub-Service"}
          type={"text"}
          value={subService}
          hideBorder={!updateProfile}
          onChange={(text) => {
            setSubService(text);
            setErrors({
              ...errors,
              subService: false,
            });
          }}
          error={errors.subService}
        />
      </div>
      <div className="su-sub-left-row-container">
        <CustomTextInput
          heading={"Website Address"}
          type={"url"}
          value={website}
          hideBorder={!updateProfile}
          onChange={(text) => {
            setWebsite(text);
            setErrors({
              ...errors,
              website: false,
            });
          }}
        />
      </div>
      <h2>Gallery Images</h2>
      <div className="su-sub-left-row-container">
        {[
          ...galleryImages,
          {
            type: "button",
          },
        ].map((element) => (
          <div
            onClick={() => {
              document.getElementById("galleryInput").click();
            }}
            style={{
              border: "solid",
              borderWidth: 1,
              borderColor: "rgba(0,0,0,0.1)",
              width: "fit-content",
              padding: element?.type != "button" ? 0 : 20,
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 10,
              borderRadius: 10,
              cursor: "pointer",
            }}
          >
            <input
              type="file"
              id="galleryInput"
              style={{
                display: "none",
              }}
              multiple={false}
              onChange={async (e) => {
                const uploaded = await getImageUri(e.target.files[0]);
                console.log(uploaded);
                if (uploaded) {
                  console.log(uploaded);

                  let tempArr = galleryImages;
                  tempArr.push(uploaded);
                  setGalleryImages(tempArr);
                }
              }}
            />
            {element?.type != "button" ? (
              <img
                src={element}
                style={{
                  height: 130,
                  width: 100,
                  objectFit: "cover",
                }}
              />
            ) : (
              <>
                <div className="pf-pf-user-logo">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    fill="#fff"
                    version="1.1"
                    id="Capa_1"
                    width="40px"
                    height="40px"
                    viewBox="0 0 45.402 45.402"
                    xmlSpace="preserve"
                  >
                    <g>
                      <path d="M41.267,18.557H26.832V4.134C26.832,1.851,24.99,0,22.707,0c-2.283,0-4.124,1.851-4.124,4.135v14.432H4.141   c-2.283,0-4.139,1.851-4.138,4.135c-0.001,1.141,0.46,2.187,1.207,2.934c0.748,0.749,1.78,1.222,2.92,1.222h14.453V41.27   c0,1.142,0.453,2.176,1.201,2.922c0.748,0.748,1.777,1.211,2.919,1.211c2.282,0,4.129-1.851,4.129-4.133V26.857h14.435   c2.283,0,4.134-1.867,4.133-4.15C45.399,20.425,43.548,18.557,41.267,18.557z" />
                    </g>
                  </svg>
                </div>
                <p
                  style={{
                    fontWeight: "600",
                  }}
                >
                  Add Image
                </p>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default ProfileForm;
