import React from "react";
import "./home.css"; // Import the CSS file
import { useNavigate } from "react-router";
const HeroThree = "./hero-three.png";

const HomeSecFive = () => {
  const navigate = useNavigate();
  return (
    <div className="home-sec-five">
      <div
        style={{
          width: "95%",
        }}
      >
        <div className="home-sec-four-container">
          <div>
            <p
              className="home-sec-four-subtitle"
              style={{
                color: "black",
              }}
            >
              How it{" "}
              <span
                style={{
                  color: "#F81D1E",
                }}
              >
                works
              </span>
            </p>
            <p
              className="home-sec-four-title"
              style={{
                color: "black",
              }}
            >
              Stay ahead with the latest digital marketing insights and trends.
            </p>
          </div>
          <button
            onClick={() => navigate("/signup")}
            className="Button-Reg"
            style={{ fontSize: "12px", fontWeight: "700" }}
          >
            Register as Business
          </button>
        </div>

        <div className="sec-five-card-container">
          <div className="home-sec-five-card">
            <img
              src={"./secFive1.png"}
              alt="textAlert"
              className="home-sec-five-card-img"
            />
            <div className="home-sec-five-card-text-container">
              <div className="step">Step 1</div>
              <p>
                Tap the alert button to instantly signal your designated
                contacts of your emergency.
              </p>
            </div>
          </div>
          {/*  */}
          <div className="home-sec-five-card">
            <img
              src={"./secFive2.png"}
              alt="textAlert"
              className="home-sec-five-card-img"
            />
            <div className="home-sec-five-card-text-container">
              <div className="step">Step 2</div>
              <p>
                Text messages to designated personal contacts …Alert generated
                and sent to designated personal contacts.
              </p>
            </div>
          </div>
          {/*  */}
          <div className="home-sec-five-card">
            <img
              src={"./secFive3.png"}
              alt="textAlert"
              className="home-sec-five-card-img"
            />
            <div className="home-sec-five-card-text-container">
              <div className="step">Step 3</div>
              <p>Video or audio record the incident (optional)</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSecFive;
