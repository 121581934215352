import React from "react";
import { useNavigate } from "react-router";
const HeroThree = "./hero-three.png";

const JoinAsPartner = () => {
  const navigate = useNavigate();
  return (
    <div className="home-sec-three">
      <div className="home-sec-three-container">
        <div style={{ width: "100%" }}>
          <img
            src={
              "https://images.unsplash.com/photo-1713623311317-d3c43a4be4cf?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            }
            alt="HomeSecThree"
            className="home-sec-three-image"
          />
        </div>

        <div className="home-sec-three-content">
          <svg
            width="70"
            height="70"
            viewBox="0 0 70 70"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.4"
              d="M26.2507 5.83337C18.609 5.83337 12.3965 12.0459 12.3965 19.6875C12.3965 27.1834 18.259 33.25 25.9007 33.5125C26.134 33.4834 26.3673 33.4834 26.5423 33.5125C26.6007 33.5125 26.6298 33.5125 26.6882 33.5125C26.7173 33.5125 26.7173 33.5125 26.7465 33.5125C34.2132 33.25 40.0757 27.1834 40.1048 19.6875C40.1048 12.0459 33.8923 5.83337 26.2507 5.83337Z"
              fill="url(#paint0_linear_404_3357)"
            />
            <path
              d="M41.0668 41.2709C32.9293 35.8459 19.6585 35.8459 11.4626 41.2709C7.75846 43.7501 5.7168 47.1042 5.7168 50.6917C5.7168 54.2792 7.75846 57.6042 11.4335 60.0542C15.5168 62.7959 20.8835 64.1667 26.2501 64.1667C31.6168 64.1667 36.9835 62.7959 41.0668 60.0542C44.7418 57.5751 46.7835 54.2501 46.7835 50.6334C46.7543 47.0459 44.7418 43.7209 41.0668 41.2709Z"
              fill="url(#paint1_linear_404_3357)"
            />
            <path
              opacity="0.4"
              d="M58.3043 21.4083C58.771 27.0666 54.746 32.025 49.1751 32.6958C49.146 32.6958 49.146 32.6958 49.1168 32.6958H49.0293C48.8543 32.6958 48.6793 32.6958 48.5335 32.7541C45.7043 32.9 43.1085 31.9958 41.1543 30.3333C44.1585 27.65 45.8793 23.625 45.5293 19.25C45.3251 16.8875 44.5085 14.7291 43.2835 12.8916C44.3918 12.3375 45.6751 11.9875 46.9876 11.8708C52.7043 11.375 57.8085 15.6333 58.3043 21.4083Z"
              fill="url(#paint2_linear_404_3357)"
            />
            <path
              d="M64.138 48.3876C63.9047 51.2168 62.0964 53.6668 59.063 55.3293C56.1464 56.9334 52.4714 57.6918 48.8255 57.6043C50.9255 55.7084 52.1505 53.3459 52.3839 50.8376C52.6755 47.2209 50.9547 43.7501 47.513 40.9793C45.5589 39.4334 43.2839 38.2084 40.8047 37.3043C47.2505 35.4376 55.3589 36.6918 60.3464 40.7168C63.0297 42.8751 64.4005 45.5876 64.138 48.3876Z"
              fill="url(#paint3_linear_404_3357)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_404_3357"
                x1="12.3965"
                y1="5.83337"
                x2="44.1216"
                y2="11.6666"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#F81D1E" />
                <stop offset="1" stop-color="#AA0506" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_404_3357"
                x1="5.7168"
                y1="37.2021"
                x2="50.7987"
                y2="49.813"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#F81D1E" />
                <stop offset="1" stop-color="#AA0506" />
              </linearGradient>
              <linearGradient
                id="paint2_linear_404_3357"
                x1="41.1543"
                y1="11.8315"
                x2="61.0457"
                y2="14.8305"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#F81D1E" />
                <stop offset="1" stop-color="#AA0506" />
              </linearGradient>
              <linearGradient
                id="paint3_linear_404_3357"
                x1="40.8047"
                y1="36.4573"
                x2="67.3681"
                y2="41.8463"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#F81D1E" />
                <stop offset="1" stop-color="#AA0506" />
              </linearGradient>
            </defs>
          </svg>

          <h2 className="home-sec-three-title">
            Join Riley’s Alert® as Business Partner
            {/* <span className="home-sec-three-subtitle">®</span> */}
          </h2>
          <p className="home-sec-three-paragraph">
            With Riley’s Alert®, you'll experience the comfort of knowing your
            loved ones are just a tap away from being alerted to your
            circumstances. Our app promotes personal safety by enabling you to
            quickly communicate your status and get assistance in any risky
            situation.
          </p>
          <br />
          <p className="home-sec-three-paragraph">
            With Riley’s Alert®, you'll experience the comfort of knowing your
            loved ones are just a tap away from being alerted to your
            circumstances. Our app promotes personal safety by enabling you to
            quickly communicate your status and get assistance in any risky
            situation.
          </p>

          <div
            style={{
              display: "flex",
              gap: "20px",
              color: "white",
              marginTop: 20,
            }}
          >
            <button onClick={() => navigate("/signup")} className="Button-Reg">
              Register as Business
            </button>

            <button
              onClick={() => navigate("/signin")}
              style={{ border: "none", background: "none" }}
            >
              Login as Business
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinAsPartner;
