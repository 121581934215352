import React from "react";
import DownloadAppSection from "../../components/downloadAppSection/DownloadAppSection";
import RegisterAsBusinessBanner from "../../components/RegisterAsBusinessBanner/RegisterAsBusinessBanner";
import HomeSecEight from "../home/home-hero/home-sec-eight";
import "./AboutUs.css";
import WhatIsRileys from "./components/WhatIsRileys";
import JoinAsPartner from "./components/JoinAsPartner";
import HomeSecFour from "../home/home-hero/home-sec-four";
import ColaborationWithPartners from "./components/ColaborationWithPartners";
function AboutUs(props) {
  return (
    <div>
      <WhatIsRileys />
      <JoinAsPartner />
      <ColaborationWithPartners />
      <HomeSecFour />
      <HomeSecEight />
      <RegisterAsBusinessBanner />
      <DownloadAppSection />
    </div>
  );
}

export default AboutUs;
