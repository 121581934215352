import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import CustomTextInput from "../../components/CustomTextInput/CustomTextInput";
import { toast } from "react-toastify";
import { Spinner } from "react-activity";
import { simplePostRequest } from "../../utils/Api";
import { MyContext } from "../../components/contextProvider/MyContextProvider";
import {
  GoogleLogin,
  useGoogleLogin,
  useGoogleOAuth,
} from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { FacebookLoginClient } from "@greatsumini/react-facebook-login";

export default function SigninForm() {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const Tag = "SigninForm";
  const history = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { user, setUser } = useContext(MyContext);
  const [errors, setErrors] = useState({
    email: false,
    password: false,
  });
  const [loading, setLoading] = useState(false);
  async function onLoginPress() {
    try {
      if (!validateForm()) {
        return toast.error("Please enter correct email and password");
      }
      setLoading(true);
      const body = {
        email,
        password,
      };
      const user = await simplePostRequest("business-profile/login", body);
      if (!user?.error) {
        console.log(user);
        localStorage.setItem("user", JSON.stringify(user.data));
        setUser(user.data);
      }
    } catch (error) {
      console.log(Tag, "onLoginPress", error);
      toast.error(error?.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  }
  const validateForm = () => {
    const newErrors = { ...errors };

    newErrors.email = !emailRegex.test(email);
    newErrors.password = password.length < 8;

    setErrors(newErrors);
    // Return false if any field is invalid
    return !Object.values(newErrors).includes(true);
  };
  async function onGoogleLogin(cred) {
    try {
      if (cred.email) {
        const user = await simplePostRequest("business-profile/socialLogin", {
          email: cred.email,
          name: cred.name,
          providerId: cred.sub,
        });
        console.log(user);
        if (user?.error) {
        }
        if (user.data.redirect) {
          history(user.data.redirect);
        } else {
          localStorage.setItem("user", JSON.stringify(user.data));

          setUser(user.data);
        }
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.message || "Something went wrong");
    }
  }

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const googleUser = await fetch(
        `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${tokenResponse.access_token}`
      );
      if (googleUser) {
        const googleUserJson = await googleUser.json();
        if (googleUserJson.email) {
          await onGoogleLogin(googleUserJson);
        }
      }
    },
  });
  const appId = "919582527015946";

  useEffect(() => {}, []);
  const facebookLogin = async () => {
    await FacebookLoginClient.loadSdk("en_Us");

    FacebookLoginClient.init({ appId, version: "v21.0" });
    FacebookLoginClient.login(
      (res) => {
        console.log(res);
        console.log("calling profile");
        FacebookLoginClient.getProfile(
          (profile) => {
            console.log("here", profile);
            if (profile.email) {
              onGoogleLogin({
                name: profile.name,
                email: profile.email,
                sub: profile.id,
              });
            }
          },
          {
            fields: "name,email,picture",
          }
        );
      },
      {
        scope: "public_profile, email",
      }
    );
  };
  return (
    <div className="si-main-container">
      <div className="si-sub">
        <div className="si-sub-left">
          <p className="si-main-heading">Business Sign In</p>
          <p
            style={{
              fontSize: 15,
              marginTop: 10,
            }}
          >
            New User?{" "}
            <NavLink
              to={"/signup"}
              // onClick={() => history("/signup")}
              style={{ textDecoration: "none", color: "#F81D1E" }}
            >
              Create an account
            </NavLink>
          </p>

          <div className="si-sub-left-row-container">
            <CustomTextInput
              heading={"Email"}
              type={"email"}
              value={email}
              error={errors.email}
              onChange={(text) => {
                setEmail(text);
                setErrors({
                  ...errors,
                  email: false,
                });
              }}
            />
            <CustomTextInput
              heading={"Password"}
              type={"password"}
              value={password}
              error={errors.password}
              onChange={(text) => {
                setPassword(text);
                setErrors({
                  ...errors,
                  password: false,
                });
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 20,
            }}
          >
            <svg
              width="5"
              height="5"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.19 2.00122H7.81C4.17 2.00122 2 4.17122 2 7.81122V16.1812C2 19.8312 4.17 22.0012 7.81 22.0012H16.18C19.82 22.0012 21.99 19.8312 21.99 16.1912V7.81122C22 4.17122 19.83 2.00122 16.19 2.00122ZM16.78 9.70122L11.11 15.3712C10.97 15.5112 10.78 15.5912 10.58 15.5912C10.38 15.5912 10.19 15.5112 10.05 15.3712L7.22 12.5412C6.93 12.2512 6.93 11.7712 7.22 11.4812C7.51 11.1912 7.99 11.1912 8.28 11.4812L10.58 13.7812L15.72 8.64122C16.01 8.35122 16.49 8.35122 16.78 8.64122C17.07 8.93122 17.07 9.40122 16.78 9.70122Z"
                fill="url(#paint0_linear_3136_3815)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_3136_3815"
                  x1="2"
                  y1="2.00122"
                  x2="24.8902"
                  y2="6.20345"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#F81D1E" />
                  <stop offset="1" stop-color="#AA0506" />
                </linearGradient>
              </defs>
            </svg>
            <p>Remember me</p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p
              style={{
                fontSize: 15,
                marginTop: 10,
              }}
            >
              <NavLink style={{ textDecoration: "none", color: "#F81D1E" }}>
                Forget Password?
              </NavLink>
            </p>
            <button
              className="Button-Reg"
              onClick={() => onLoginPress()}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 20,
                willChange: "60%",
                maxWidth: "35%",
                flexGrow: 1,
              }}
              disabled={loading}
            >
              {loading ? <Spinner /> : "Login"}
            </button>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div className="si-sepreator" />
            <p
              style={{
                color: "black",
                textAlign: "center",
                margin: 20,
              }}
            >
              or
            </p>
            <div className="si-sepreator" />
          </div>
          <div className="socialContainer">
            <button
              onClick={() => login()}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 10,
                border: "solid",
                borderWidth: 1,
                borderColor: "#EBEAED",
                padding: 8,
                boxSizing: "border-box",
                borderRadius: 10,
                cursor: "pointer",
                backgroundColor: "white",
              }}
            >
              <svg
                width="14"
                height="15"
                viewBox="0 0 14 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.7709 7.86236C13.7709 7.40546 13.7331 6.94608 13.6525 6.49658H7.18457V9.0849H10.8885C10.7348 9.91968 10.2409 10.6581 9.51776 11.1274V12.8068H11.7275C13.0251 11.6362 13.7709 9.90733 13.7709 7.86236Z"
                  fill="#4285F4"
                />
                <path
                  d="M7.18442 14.4293C9.03384 14.4293 10.5935 13.8341 11.7299 12.8067L9.52013 11.1273C8.90534 11.5372 8.11165 11.7694 7.18694 11.7694C5.39799 11.7694 3.88116 10.5864 3.33692 8.99585H1.05664V10.7272C2.22072 12.9969 4.5917 14.4293 7.18442 14.4293Z"
                  fill="#34A853"
                />
                <path
                  d="M3.3343 8.99621C3.04707 8.16143 3.04707 7.25749 3.3343 6.42271V4.69141H1.05658C0.0840059 6.59066 0.0840059 8.82826 1.05658 10.7275L3.3343 8.99621Z"
                  fill="#FBBC04"
                />
                <path
                  d="M7.18442 3.64647C8.16204 3.63165 9.10691 3.99224 9.81493 4.65414L11.7727 2.73513C10.533 1.5941 8.8877 0.966779 7.18442 0.986537C4.5917 0.986537 2.22072 2.419 1.05664 4.69118L3.3344 6.42249C3.87613 4.82949 5.39547 3.64647 7.18442 3.64647Z"
                  fill="#EA4335"
                />
              </svg>
              <span>Sign in with Google</span>
            </button>
            <button
              onClick={() => facebookLogin()}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 10,
                border: "solid",
                borderWidth: 1,
                borderColor: "#EBEAED",
                padding: 8,
                boxSizing: "border-box",
                borderRadius: 10,
                cursor: "pointer",
                backgroundColor: "white",
              }}
            >
              <svg
                width="14"
                height="15"
                viewBox="0 0 14 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="7.04884" cy="7.70802" r="6.72169" fill="#1977F3" />
                <path
                  d="M9.66575 9.65125L9.96351 7.70781H8.09947V6.44684C8.09947 5.91561 8.35942 5.39667 9.19502 5.39667H10.0429V3.74247C10.0429 3.74247 9.27347 3.61108 8.53807 3.61108C7.00298 3.61108 5.99912 4.54121 5.99912 6.2266V7.70781H4.29199V9.65125H5.99912V14.3482C6.3413 14.4021 6.69199 14.4295 7.04929 14.4295C7.4066 14.4295 7.75729 14.4011 8.09947 14.3482V9.65125H9.66575Z"
                  fill="white"
                />
              </svg>

              <span>Sign in with Facebook</span>
            </button>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          ></div>
          <div className="si-sub-left-policy-container">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: 20,
                justifyContent: "center",
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.19 2.00122H7.81C4.17 2.00122 2 4.17122 2 7.81122V16.1812C2 19.8312 4.17 22.0012 7.81 22.0012H16.18C19.82 22.0012 21.99 19.8312 21.99 16.1912V7.81122C22 4.17122 19.83 2.00122 16.19 2.00122ZM16.78 9.70122L11.11 15.3712C10.97 15.5112 10.78 15.5912 10.58 15.5912C10.38 15.5912 10.19 15.5112 10.05 15.3712L7.22 12.5412C6.93 12.2512 6.93 11.7712 7.22 11.4812C7.51 11.1912 7.99 11.1912 8.28 11.4812L10.58 13.7812L15.72 8.64122C16.01 8.35122 16.49 8.35122 16.78 8.64122C17.07 8.93122 17.07 9.40122 16.78 9.70122Z"
                  fill="url(#paint0_linear_3136_3815)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_3136_3815"
                    x1="2"
                    y1="2.00122"
                    x2="24.8902"
                    y2="6.20345"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#F81D1E" />
                    <stop offset="1" stop-color="#AA0506" />
                  </linearGradient>
                </defs>
              </svg>
              <p>
                I agree to Riley’s Alert{" "}
                <NavLink className={"si-link"}>Privacy Policy</NavLink> and{" "}
                <NavLink className={"si-link"}>Terms of Use</NavLink>
              </p>
            </div>
          </div>
        </div>
        <img src="./signin.png" className="si-form-image" />
      </div>
    </div>
  );
}
