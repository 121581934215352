import React from "react";
import "./home.css"; // Import the CSS file
const HeroThree = "./hero-three.png";

const HomeSecSix = () => {
  const points = [
    "Medical Emergencies",
    "Missing Persons",
    "Natural Disasters",
    "Outdoor Adventures",
    "Personal Safety",
    "Public Unrest",
    "Roadside Assistance",
    "School Shootings",
    "Traffic Stops",
    "Workplace Energencies",
    "12+ more",
  ];
  return (
    <div className="home-sec-five">
      <div
        style={{
          width: "95%",
        }}
      >
        <div className="home-sec-four-container">
          <div>
            <p
              className="home-sec-four-subtitle"
              style={{
                color: "black",
              }}
            >
              When You Need{" "}
              <span
                style={{
                  color: "#F81D1E",
                }}
              >
                Riley’s Alert®
              </span>
            </p>
            <p
              className="home-sec-four-title"
              style={{
                color: "black",
              }}
            >
              In times of distress, Riley’s Alert® rapidly engages your support
              network in a variety of emergencies and high-risk situations. Here
              are just some examples of when the app's capabilities could prove
              invaluable
            </p>
          </div>
        </div>

        <div className="sec-five-point-container">
          {points.map((element) => {
            return <div className="points">{element}</div>;
          })}
        </div>
        <p
          className="home-sec-four-title"
          style={{
            color: "black",
          }}
        >
          With Riley’s Alert® on your smartphone, you're never alone when facing
          an emergency. Our app keeps you connected to your loved ones with just
          a tap. Don't go another day without the assurance of Riley’s Alert®.
          Download our personal safety app today and experience the convenience
          of enhanced security and rapid assistance, just a tap away. Download
          the App Today!
        </p>
      </div>
    </div>
  );
};

export default HomeSecSix;
