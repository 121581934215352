import React from "react";
import "./navbar.css";
import { NavLink, useNavigate } from "react-router-dom";
const RileyLogo = "/riley-logo.png";

const Navbar = () => {
  const navigate = useNavigate();
  return (
    <div className="Navbar-center">
      <div className="Navbar-Main">
        <NavLink to={"/"} style={{}}>
          <img src={RileyLogo} alt="" className="Logo" />
        </NavLink>
        <div className="Links-Main">
          <div className="links">
            <span className="color-gradient" onClick={() => navigate("/")}>
              Home
            </span>
            <span onClick={() => navigate("/about-us")}>About Us</span>

            <span>Blogs</span>
            <span>Contact Us</span>
            <span>Download App</span>
          </div>

          <sv
            className="seprator"
            style={{
              marginRight: 40,
              marginLeft: 40,
            }}
            width="2"
            height="26"
            viewBox="0 0 2 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.2"
              d="M1 1L0.999999 25"
              stroke="#585858"
              stroke-linecap="round"
            />
          </sv>

          <div className="Button-Main">
            <button onClick={() => navigate("/signin")} className="Login-Btn">
              Login as Business
            </button>

            <button onClick={() => navigate("/signup")} className="Button-Reg">
              Register as Business
            </button>
          </div>
        </div>
      </div>
      {/* {} */}
      <div className="Links-Main-Mobile">
        <div className="links-mobile">
          <span className="color-gradient" onClick={() => navigate("/")}>
            Home
          </span>
          <span onClick={() => navigate("/about-us")}>About Us</span>

          <span>Blogs</span>
          <span>Contact Us</span>
          <span>Download App</span>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
