import React from "react";
import "./home.css"; // Import the CSS file
const HeroThree = "./hero-three.png";
const HomeSecFour = () => {
  return (
    <div className="home-sec-four">
      <div
        style={{
          width: "95%",
        }}
      >
        <div className="home-sec-four-container">
          <div>
            <p className="home-sec-four-title">Our Features</p>
            <p className="home-sec-four-subtitle">
              Riley’s Alert® Feature Suite
            </p>
          </div>
          <p
            style={{
              color: "white",
              fontSize: "16px",
              fontWeight: "400",
              margin: "0",
            }}
          >
            Riley’s Alert® offers peace of mind through key personal safety
            features.
          </p>
        </div>

        <div className="card-container">
          <div className="home-sec-four-card">
            <img
              src={"./textAlert.png"}
              alt="textAlert"
              className="home-sec-four-card-img"
            />
            <div className="home-sec-four-card-text-container">
              <p className="home-sec-four-card-title">SMS Text Alerts</p>
              <p className="home-sec-four-card-text">
                Notify up to 3 designated personal contacts sharing your
                location and need for assistance.
              </p>
            </div>
          </div>
          {/*  */}
          <div className="home-sec-four-card">
            <img
              src={"./scoreAlert.png"}
              alt="textAlert"
              className="home-sec-four-card-img"
            />
            <div className="home-sec-four-card-text-container">
              <p className="home-sec-four-card-title">
                Credit Score Monitoring
              </p>
              <p className="home-sec-four-card-text">
                Provide your GPS coordinates and real-time location to your
                designated contacts for potential improvement of coordination
                and response with emergency personnel.
              </p>
            </div>
          </div>
          {/*  */}
          <div className="home-sec-four-card">
            <img
              src={"./paymetnAlert.png"}
              alt="textAlert"
              className="home-sec-four-card-img"
            />
            <div className="home-sec-four-card-text-container">
              <p className="home-sec-four-card-title">Bill Payment Reminder</p>
              <p className="home-sec-four-card-text">
                Activate your phone's camera and microphone to capture video and
                audio of your surroundings and transmit to secure servers.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSecFour;
