import React, { useEffect, useState } from "react";
import SignupForm from "./SignUpForm";
import HomeSecEight from "../home/home-hero/home-sec-eight";
import DownloadAppSection from "../../components/downloadAppSection/DownloadAppSection";
import "./Signup.css";
export default function Signup() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }, []);
  return (
    <>
      <SignupForm />
      <HomeSecEight />
      <DownloadAppSection />
    </>
  );
}
