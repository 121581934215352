import React from "react";
import HomeSecEight from "../home/home-hero/home-sec-eight";
import DownloadAppSection from "../../components/downloadAppSection/DownloadAppSection";
import "./Signin.css";
import SigninForm from "./SigninForm";
export default function Signin() {
  return (
    <>
      <SigninForm />
      <HomeSecEight />
      <DownloadAppSection />
    </>
  );
}
