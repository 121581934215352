import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Navbar from "./components/navbar/navbar";
import Sidebar from "./components/sidebar/sidebar";
import Footer from "./components/footer/Footer";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { MyContextProvider } from "./components/contextProvider/MyContextProvider";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { NotificationProvider } from "./components/NotificationProvider/NotificationProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <NotificationProvider>
    <MyContextProvider>
      <GoogleOAuthProvider clientId="517987505772-ai46pedi7hp82g5m0nod7q62r3va2poq.apps.googleusercontent.com">
        <BrowserRouter>
          <Navbar />
          <App />
          <Footer />
          <ToastContainer />
        </BrowserRouter>
      </GoogleOAuthProvider>
    </MyContextProvider>
  </NotificationProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
