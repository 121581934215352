import React from "react";
import "./home.css";
import { useNavigate } from "react-router";

const HeroImage = "./Hero-content.jpeg";
const BaseButton = "./Button.base.png";

const HomeHero = () => {
  const navigate = useNavigate();
  return (
    <div className="hero-container" style={{ marginTop: 50 }}>
      <div className="gradient-overlay"></div>

      <div className="centered-content">
        <div className="centered-content-title">
          Become a Partner with Riley’s Alert
        </div>

        <div className="centered-content-subtitle">
          Riley’s Alert will try his best to make you safe in every situation.
        </div>

        <div className="centered-content-button">
          <button
            onClick={() => navigate("/signup")}
            className="Button-Reg"
            style={{ fontSize: "16px", fontWeight: "700" }}
          >
            Register as Business
          </button>
        </div>

        <div className="base-button">
          <img src={BaseButton} alt="" width={35} />
        </div>
      </div>

      <div className="image-container">
        <img src={HeroImage} alt="Hero" />
      </div>
    </div>
  );
};

export default HomeHero;
