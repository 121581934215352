import React from "react";
import HomeHero from "./features/home/home-hero/homeHero";
import HeroSecTwo from "./features/home/home-hero/hero-sec-two";
import HomeSecThree from "./features/home/home-hero/home-sec-three";
import HomeSecFour from "./features/home/home-hero/home-sec-four";
import HomeSecFive from "./features/home/home-hero/home-sec-five";
import HomeSecSix from "./features/home/home-hero/home-sec-six";
import HomeSecSeven from "./features/home/home-hero/home-sec-seven";
import HomeSecEight from "./features/home/home-hero/home-sec-eight";
import RegisterAsBusinessBanner from "./components/RegisterAsBusinessBanner/RegisterAsBusinessBanner";
import DownloadAppSection from "./components/downloadAppSection/DownloadAppSection";
import Footer from "./components/footer/Footer";
const home = () => {
  return (
    <>
      <HomeHero />
      <HeroSecTwo />
      <HomeSecThree />
      <HomeSecFour />
      <HomeSecFive />
      <HomeSecSix />
      <HomeSecSeven />
      <HomeSecEight />
      <RegisterAsBusinessBanner />
      <DownloadAppSection />
    </>
  );
};

export default home;
