import React, { useState } from "react";
import "./Profile.css";
import ProfileForm from "./ProfileForm";
import Membership from "./Membership";
export default function Profile() {
  const [selected, setSelected] = useState(1);
  return (
    <div className="pf-main-container">
      <div className="pf-sub">
        <section className="pf-first-container">
          <div
            onClick={() => setSelected(1)}
            className={`pf-st-button ${selected == 1 && "pf-st-selected"}`}
          >
            Profile
          </div>
          <div
            onClick={() => setSelected(2)}
            className={`pf-st-button ${selected == 2 && "pf-st-selected"}`}
          >
            Membership
          </div>
        </section>
        <section className="pf-second-container">
          {selected == 1 ? <ProfileForm /> : <Membership />}
        </section>
      </div>
    </div>
  );
}
