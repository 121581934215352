import React, { useContext, useEffect } from "react";
import Home from "./home";
import { Route, Routes, useNavigate } from "react-router";
import Signup from "./features/signup/Signup";
import Signin from "./features/signin/Signin";
import { MyContext } from "./components/contextProvider/MyContextProvider";
import Profile from "./features/profile/Profile";
import {
  notificationInitialState,
  NotificationProvider,
  useNotification,
} from "./components/NotificationProvider/NotificationProvider";
import { Notification } from "./components/Notification/Notification";
import AboutUs from "./features/aboutus/AboutUs";
import PrivacyAndTerms from "./features/privacyAndTerms/PrivacyAndTerms";
import PrivacyPolicyAndTerms from "./data/PrivacyPolicyAndTerms";
import A2pSmsService from "./features/a2pSmsService/A2pSmsService";
// import ProfilePage from "./features/ProfilePage/ProfilePage";
const App = () => {
  const { user, setUser } = useContext(MyContext);
  const navigate = useNavigate();
  const { notification, triggerNotification } = useNotification();

  useEffect(() => {
    let localUser = localStorage.getItem("user");
    if (localUser && localUser != JSON.stringify(user)) {
      localUser = JSON.parse(localUser);

      setUser(localUser);
    }
    if (user) {
      // navigate("/profile");
    }
  }, [user]);
  return (
    <>
      <Notification
        message={notification.message}
        type={notification.type}
        onClose={() => triggerNotification(notificationInitialState)}
      />
      <Routes>
        <Route index element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route
          path="/terms-and-conditions"
          element={
            <PrivacyAndTerms
              heading={"Terms & Conditions"}
              subHeading={"Our relaxing Terms"}
              data={PrivacyPolicyAndTerms.policy}
            />
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <PrivacyAndTerms
              heading={"Privacy Policy"}
              subHeading={"Remember Riley's Policies"}
              data={PrivacyPolicyAndTerms.policy}
            />
          }
        />
        <Route path="/profile" element={<Profile />} />
        <Route path="/a2p-sms-service" element={<A2pSmsService />} />

        {!user && (
          <>
            <Route path="/signup" element={<Signup />} />
            <Route path="/signin" element={<Signin />} />
          </>
        )}
      </Routes>
    </>
  );
};

export default App;
