import React, { useEffect } from "react";
import HomeSecFour from "../home/home-hero/home-sec-four";
import HomeSecSeven from "../home/home-hero/home-sec-seven";
import DownloadAppSection from "../../components/downloadAppSection/DownloadAppSection";
import RegisterAsBusinessBanner from "../../components/RegisterAsBusinessBanner/RegisterAsBusinessBanner";
import PrivacyPolicyAndTerms from "../../data/PrivacyPolicyAndTerms";

function A2pSmsService(props) {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }, [props]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "95%",
          display: "flex",
          flexDirection: "column",
          gap: 100,
          marginTop: "10vh",
        }}
      >
        {PrivacyPolicyAndTerms.a2p.map((element) => {
          return (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection:
                  element.number && element.number % 2 == 0
                    ? "row"
                    : "row-reverse",
                justifyContent: "space-between",
              }}
            >
              <img
                src={element.image}
                style={{
                  width: element.number ? "45%" : "55%",
                  objectFit: "cover",
                  borderRadius: 8,
                }}
              />
              <div
                style={{
                  width: element.number ? "45%" : "40%",
                  display: "flex",
                  boxSizing: "border-box",
                  paddingTop: 40,
                  flexDirection: "column",
                  justifyContent: element.button
                    ? "space-evenly"
                    : "flex-start",
                }}
              >
                {element.number && (
                  <h1
                    style={{
                      color: "#AA0506",
                      fontSize: 50,
                      fontWeight: "bold",
                      marginBottom: 20,
                    }}
                  >
                    {element.number}.
                  </h1>
                )}
                {element.mainHeading && (
                  <h1
                    style={{
                      color: "#000",
                      fontSize: 40,
                      fontWeight: "bold",
                      marginBottom: 20,
                    }}
                  >
                    {element.mainHeading}.
                  </h1>
                )}
                {element.heading && (
                  <h1
                    style={{
                      color: "#000",
                      fontSize: 28,
                      fontWeight: "bold",
                      marginBottom: 20,
                    }}
                  >
                    {element.heading}.
                  </h1>
                )}
                {element.description && (
                  <h1
                    style={{
                      color: "#000",
                      fontSize: 20,
                      fontWeight: "normal",
                      marginBottom: 20,
                    }}
                  >
                    {element.description}.
                  </h1>
                )}
                {element.button && (
                  <button
                    className="Button-Reg"
                    style={{
                      alignSelf: "flex-start",
                    }}
                  >
                    {element.button}.
                  </button>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <RegisterAsBusinessBanner />
      <DownloadAppSection />
    </div>
  );
}

export default A2pSmsService;
