import React, { useContext, useEffect, useState } from "react";
import { getUserData } from "../../utils/ProfileApi";
import { MyContext } from "../../components/contextProvider/MyContextProvider";
import {
  cancelSubscription,
  createCheckout,
  getSubscriptionDetail,
} from "../../utils/Stripe";
import { useNotification } from "../../components/NotificationProvider/NotificationProvider";
import styles from "./Planitem.module.scss";

function Membership(props) {
  const [userData, setUserData] = useState();
  const { user, setUser } = useContext(MyContext);
  const { notification, triggerNotification } = useNotification();

  const [subscriptionDate, setSubServicecriptionDate] = useState();
  const [productsLoading, setProductsLoading] = useState(false);
  const [userState, setUserState] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [subscriptionUpdate, setSubscriptionUpdate] = useState(false);

  const token = user?.jwtoken;

  const fetchData = async () => {
    let response;
    try {
      if (token) {
        response = await getUserData(token);

        if (response?.status == 200) {
          setUserData(response?.data?.data?.status);
        } else {
          console.error("Error fetching user data:");
        }
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const fetchSubscriptionData = async () => {
    let response;
    console.log(token);
    try {
      if (token) {
        response = await getSubscriptionDetail(token);
        console.log(response);
        setSubServicecriptionDate(response?.data?.subscription);
        if (response?.status == 200) {
        } else {
          console.error("Error fetching user data:");
        }
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
  function formatDate(dateString) {
    if (!dateString) return "";
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
    return formattedDate;
  }
  const subscriptionCancel = async () => {
    let response;
    try {
      if (token) {
        response = await cancelSubscription(token);

        if (response?.status == 200) {
          triggerNotification({
            message: response?.data?.message,
            type: "success",
          });
          setShowModal(false);
          setSubscriptionUpdate(true);
          setUserState(true);
        } else {
          console.error("Error fetching user data:");
        }
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const createStripeCheckout = async (priceId) => {
    try {
      const res = await createCheckout(token, priceId);
      if (res?.status == 200) {
        const checkoutUrl = res?.data?.checkoutUrl;
        if (checkoutUrl) {
          window.location.href = checkoutUrl;
        } else {
          console.log("Checkout URL not found in response.");
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleFreeSubscriptionChange = () => {
    if (userData == "free") {
      triggerNotification({
        message: "You are already a free user.",
        type: "warning",
      });
      return;
    }
    setShowModal(true);
  };

  const handlePaidSubscriptionChange = () => {
    if (userData == "paid") {
      triggerNotification({
        message: "You are already a paid user.",
        type: "warning",
      });
      return;
    }
    createStripeCheckout("price_1P8IU706dWei3BV7NVN6APsY");
  };
  useEffect(() => {
    fetchData();
    fetchSubscriptionData();
  }, [userState, setUserState, user]);
  return (
    <div className="pf-pf-container">
      {showModal && (
        <div className={styles.modalOverlay}>
          <div className={styles.modal}>
            <span
              className={styles.closeButton}
              onClick={() => setShowModal(false)}
            >
              X
            </span>
            <div className={styles.modalContent}>
              <p>
                Are you sure you want to change your membership to a free
                profile?
              </p>
              <div className={styles.buttonsContainer}>
                <button
                  className={styles.noButton}
                  onClick={() => setShowModal(false)}
                >
                  No
                </button>
                <button
                  className={styles.yesButton}
                  onClick={() => subscriptionCancel()}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <h2 className="pf-pf-heading">Current Membership: {userData}</h2>
      <h2 className="pf-pf-heading">
        Membership Period:{" "}
        {userData == "free"
          ? "Lifetime"
          : `${formatDate(
              subscriptionDate?.currentPeriodStart
            )} To ${formatDate(subscriptionDate?.currentPeriodEnd)}`}
      </h2>
      <div
        className="pf-mb-mb-container"
        onClick={handleFreeSubscriptionChange}
      >
        <div className="pf-mb-mb-button">
          <h1 className="pf-pf-heading">Free</h1>
          <h3 style={{ fontWeight: 400 }}>$0.0</h3>
        </div>
        <div className="pf-mb-mb-button" onClick={handlePaidSubscriptionChange}>
          <h1 className="pf-pf-heading">Paid</h1>
          <h3 style={{ fontWeight: 400 }}>$50.0</h3>
        </div>
      </div>
    </div>
  );
}

export default Membership;
