import React from "react";
import "./RegisterAsBusinessBanner.css"; // Import the CSS file
import { useNavigate } from "react-router";

const RegisterAsBusinessBanner = () => {
  const navigate = useNavigate();
  return (
    <div className="main-container ">
      <div className="sub-container">
        <div>
          <p className="title">Are you a business?</p>
          <p className="sub-title">Register for our business directory today</p>
        </div>
        <button
          onClick={() => navigate("signup")}
          className="Button-Reg"
          style={{ fontSize: "16px", fontWeight: "700" }}
        >
          Register as Business
        </button>
      </div>
    </div>
  );
};

export default RegisterAsBusinessBanner;
