import React, { useEffect } from "react";
import HomeSecEight from "../home/home-hero/home-sec-eight";
import RegisterAsBusinessBanner from "../../components/RegisterAsBusinessBanner/RegisterAsBusinessBanner";
import DownloadAppSection from "../../components/downloadAppSection/DownloadAppSection";

function PrivacyAndTerms(props) {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }, [props]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: "10vh",
      }}
    >
      <h4 style={{ margin: 0 }}>{props.subHeading}</h4>
      <h1 className="si-main-heading">{props.heading}</h1>
      <div
        style={{
          width: "95%",
          paddingTop: 30,
        }}
      >
        {props.data.map((element) => {
          return (
            <>
              <p
                style={{
                  fontSize: 20,
                  fontWeight: "400",
                }}
              >
                {element}
              </p>
              <br />
              <br />
            </>
          );
        })}
      </div>
      <HomeSecEight />
      <RegisterAsBusinessBanner />
      <DownloadAppSection />
    </div>
  );
}

export default PrivacyAndTerms;
