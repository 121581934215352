import React from "react";

function WhatIsRileys(props) {
  return (
    <div className="wir-main-container">
      <div className="wir-main-item-container">
        <svg
          width="70"
          height="70"
          viewBox="0 0 70 70"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.4"
            d="M35.0007 64.1666C51.109 64.1666 64.1673 51.1082 64.1673 34.9999C64.1673 18.8916 51.109 5.83325 35.0007 5.83325C18.8923 5.83325 5.83398 18.8916 5.83398 34.9999C5.83398 51.1082 18.8923 64.1666 35.0007 64.1666Z"
            fill="url(#paint0_linear_3220_4516)"
          />
          <path
            d="M35 40.1041C36.1958 40.1041 37.1875 39.1124 37.1875 37.9166V23.3333C37.1875 22.1374 36.1958 21.1458 35 21.1458C33.8042 21.1458 32.8125 22.1374 32.8125 23.3333V37.9166C32.8125 39.1124 33.8042 40.1041 35 40.1041Z"
            fill="url(#paint1_linear_3220_4516)"
          />
          <path
            d="M37.684 45.5584C37.5382 45.2084 37.334 44.8876 37.0715 44.5959C36.7798 44.3334 36.459 44.1292 36.109 43.9834C35.409 43.6917 34.5923 43.6917 33.8923 43.9834C33.5423 44.1292 33.2215 44.3334 32.9298 44.5959C32.6673 44.8876 32.4632 45.2084 32.3173 45.5584C32.1715 45.9084 32.084 46.2876 32.084 46.6667C32.084 47.0459 32.1715 47.4251 32.3173 47.7751C32.4632 48.1542 32.6673 48.4459 32.9298 48.7376C33.2215 49.0001 33.5423 49.2042 33.8923 49.3501C34.2423 49.4959 34.6215 49.5834 35.0007 49.5834C35.3798 49.5834 35.759 49.4959 36.109 49.3501C36.459 49.2042 36.7798 49.0001 37.0715 48.7376C37.334 48.4459 37.5382 48.1542 37.684 47.7751C37.8298 47.4251 37.9173 47.0459 37.9173 46.6667C37.9173 46.2876 37.8298 45.9084 37.684 45.5584Z"
            fill="url(#paint2_linear_3220_4516)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_3220_4516"
              x1="5.83398"
              y1="5.83325"
              x2="72.6282"
              y2="18.1016"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#F81D1E" />
              <stop offset="1" stop-color="#AA0506" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_3220_4516"
              x1="32.8125"
              y1="21.1458"
              x2="37.9818"
              y2="21.3649"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#F81D1E" />
              <stop offset="1" stop-color="#AA0506" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_3220_4516"
              x1="32.084"
              y1="43.7646"
              x2="38.7623"
              y2="44.9944"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#F81D1E" />
              <stop offset="1" stop-color="#AA0506" />
            </linearGradient>
          </defs>
        </svg>
        <p className="si-main-heading" style={{ margin: 20 }}>
          What is Riley’s Alert®?
        </p>
        <p>
          Riley’s Alert® is an innovative personal safety app that provides a
          needed layer of personal security and assurance for you and designated
          contacts during distressing situations. At Riley’s Alert®, our mission
          is to provide families with peace of mind and an enhanced sense of
          security during life's most distressing situations. We understand how
          quickly circumstances can change and how precious the safety of your
          loved ones is.
        </p>
        <img src="./whatisrileys.png" className="wir-banner" />
        <p>
          That's why we created an innovative app to keep you connected and
          aware when it matters most. Riley’s Alert® ensures your designated
          family members and friends receive instant alerts about critical
          events or emerging concerns impacting you or your loved ones. Our app
          encourages safety and provides assurance that help is just a tap away.
        </p>
        <button
          className="Button-Reg"
          style={{
            marginTop: 40,
          }}
        >
          Download App
        </button>
      </div>
    </div>
  );
}

export default WhatIsRileys;
